import { detailColums } from "./config/index";
import { AutoBiddingApi } from "@/api";
import Mixin from "@/utils/mixin";
export default {
  name: "autoBiddingDetail",
  // 定义属性
  data: function data() {
    return {
      columns: detailColums,
      dataSource: [],
      loading: false,
      infoData: {},
      previewVisible: false,
      previewImage: "",
      page: 1,
      size: 20,
      total: 0
    };
  },
  mixins: [Mixin],
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      this.loading = true;
      AutoBiddingApi.biddingInfo({
        id: this.$route.query.id
      }).then(function (res) {
        _this.loading = false;
        _this.infoData = res;
      });
    },
    getList: function getList() {
      var _this2 = this;
      AutoBiddingApi.infoListlog({
        id: this.$route.query.id,
        page: this.page,
        size: this.size
      }).then(function (res) {
        _this2.loading = false;
        _this2.dataSource = res.list;
        _this2.total = res.total;
      });
    },
    preview: function preview() {
      var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      this.previewImage = previewImage;
      this.previewVisible = true;
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.selectedRowKeys = [];
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    }
  },
  components: {},
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.getDetail();
    this.getList();
  }
};