var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("a-card", {
    staticClass: "container_a_card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("基本信息")]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "content_left"
  }, [_c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("内部货号")]), _c("span", {
    staticClass: "content_content"
  }, [_vm._v(_vm._s(_vm.infoData.sku || "---"))])]), _c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("尺码")]), _c("span", {
    staticClass: "content_content"
  }, [_vm._v(_vm._s(_vm.infoData.size || "---"))])]), _c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("商品图片")]), _c("span", {
    staticClass: "content_content"
  }, [_vm.infoData.imgurls ? _c("img", {
    staticClass: "info_img",
    attrs: {
      src: _vm.filterCoverImg(_vm.infoData.imgurls)
    },
    on: {
      click: function click($event) {
        return _vm.preview(_vm.infoData.imgurls);
      }
    }
  }) : _c("span", [_vm._v("---")])])])]), _c("div", {
    staticClass: "content_left"
  }, [_c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("外部货号")]), _c("span", {
    staticClass: "content_content"
  }, [_vm._v(" " + _vm._s(_vm.infoData.outsku || "---"))])]), _c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("最新报价($)")]), _c("span", {
    staticClass: "content_content"
  }, [_vm._v(_vm._s(_vm.infoData.price || "---"))])]), _c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("售价($)")]), _c("span", {
    staticClass: "content_content"
  }, [_vm._v(_vm._s(_vm.infoData.minprice || "---"))])])]), _c("div", {
    staticClass: "content_left"
  }, [_c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("更新频率")]), _c("span", {
    staticClass: "content_content"
  }, [_vm._v(_vm._s(_vm.infoData.rate || "---"))])]), _c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("上次更新时间")]), _c("span", {
    staticClass: "content_content"
  }, [_vm._v(_vm._s(_vm.infoData.updatetime || "---"))])]), _c("div", {
    staticClass: "content_con"
  }, [_c("span", {
    staticClass: "content_title"
  }, [_vm._v("状态")]), _c("p", {
    staticClass: "content_content",
    staticStyle: {
      display: "inline-block"
    }
  }, [_vm.infoData.status == 0 ? _c("span", [_vm._v("上架")]) : _vm._e(), _vm.infoData.status == 1 ? _c("span", [_vm._v("下架 "), _vm.infoData.reason != "" ? _c("span", [_vm._v(_vm._s(_vm.infoData.reason))]) : _vm._e()]) : _vm._e(), _vm.infoData.status == 2 ? _c("span", [_vm._v("异常 "), _vm.infoData.reason != "" ? _c("span", [_vm._v(_vm._s(_vm.infoData.reason))]) : _vm._e()]) : _vm._e()])])])])]), _c("a-card", {
    staticClass: "container_a_card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("操作日志")]), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [_c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };